import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/dashboard/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home
  },
  {
    path: '/employees',
    name: 'Employees',
    component: () => import( '../views/Employees/Employees.vue')
  },
  {
    path: '/employeesnew',
    name: 'EmployeesNew',
    component: () => import( '../views/EmployeesNew/EmployeesNew.vue')
  },
  {
    path: '/transfert/employee/:jobid',
    name: 'BankTransfert',
    component: () => import( '../views/BankTransfert/BankTransfert.vue')
    
  },
  {
      path: '/utilisateurs',
      name: 'Users',
      component: () =>
          import ('../views/Users/Users.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
