import Vue from 'vue'
import axios from 'axios'
import Login from './views/Login'
import './plugins/fontawesome'
import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
//import Vuetable from 'vuetable-2'

import { BootstrapVue, IconsPlugin, NavPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(NavPlugin)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)



if (process.env.NODE_ENV == 'development') {
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/scc_app_2';
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
}

// Date picker
import datePicker from 'vue-bootstrap-datetimepicker';
Vue.use(datePicker)

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);
//Vue.$toast.open({/* options */});
let instance = Vue.$toast.open('You did it!');
// Force dismiss specific toast
instance.dismiss();
// Dismiss all opened toast immediately
Vue.$toast.clear();

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

//import 'bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'jquery/dist/jquery.min.js'

import router from './router'

Vue.config.productionTip = false

// const vm = new Vue({
//     router,
//     render: h => h(App)
// }).$mount('#app');


// router.beforeEach((to, from, next) => {
//     console.log("CHANGE");
//     vm.$emit('change-load', 'loading');
//     next();
// })
// router.afterEach(() => {
//     vm.$emit('change-load', 'loaded');
// })


axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function(response) {
    var user = response.data;

    var vm = null;
    if (user.users_scc_app_id == '' || user.users_scc_app_id == null || user.users_scc_app_id == undefined) {
        vm = new Vue({
            router,
            render: h => h(Login)
        }).$mount('#app');
    } else {
        vm = new Vue({
            router,
            render: h => h(App, {
                props: {
                    users_info: user
                }
            }),
        }).$mount('#app');
    }



    router.beforeEach((to, from, next) => {
        vm.$emit('change-load', 'loading', user);
        axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function(response) {
            var user = response.data;
            console.log(user);
            if (user.users_scc_app_id == '' || user.users_scc_app_id == null || user.users_scc_app_id == undefined) {
                location.reload();
            } else {
                next();
            }
        });

    })
    router.afterEach(() => {
        vm.$emit('change-load', 'loaded', user);
    })
});